import Alpine from 'alpinejs';
import ThemeStore from "@/Stores/ThemeStore.js";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Frontend/matchHeight.js';
import './Frontend/hashChange.js';
import './Frontend/renderScroll.js';
import './Frontend/clickable.js';
import './Frontend/debugbar.js';
import './Frontend/splide.js';
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

import.meta.glob([
    '../fonts/**',
]);

window.ThemeStore = ThemeStore;
window.uuidv4 = uuidv4;
window.axios = axios;
document.addEventListener('alpine:init', () => {
    AOS.init({
        duration: 200,
        easing: 'ease-in-out',
    });
})

window.Alpine = Alpine;
Alpine.start();





